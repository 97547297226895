import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Header from "../components/events/header"
import Section1 from "../components/events/section1"
import Section2 from "../components/events/section2"
import Section3 from "../components/events/section3"
import Section4 from "../components/events/section4"
import SEO from "../components/seo"

const EventsPage = () => (
  <Layout>
    <SEO title="Events" />
    <Navigation isAbsolute={false} inverted currentPage="events" />
    <Header />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
  </Layout>
)

export default EventsPage
