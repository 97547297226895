import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import pitchIcon from '../icons/3_min_pitch_icon.svg'

const Section1 = ({}) => (
  <section className="container lg:flex py-10 md:py-26 lg:py-24 xl2:py-40">
    <div className="lg:w-10/12">
      <div className="text-sm text-gradient uppercase font-display font-bold">Pitch Session</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Ab auf die Bühne – and go!</h2>
      <hr className="border-meteor-500 border-2 w-1/5 my-6" />
      <p className="font-light leading-relaxed">
        Mittelpunkt der Pitch Nights ist die Pitch Session. In dieser können sich alle präsentieren, 
        die auf der Suche nach einer Mitgründerin sind. In einem 3-minütigem Pitch heißt es alles sagen, 
        was es braucht, um eine zukünftige Mitgründerin zum Mitgründen zu überzeugen. Problem, Lösung, Markt. 
        Und natürlich Aufgaben und Profil der offenen Co-Founder Position. Das ist ein perfekter 
        Ausgangspunkt, um mit interessierten Co-Foundern ins Gespräch zu kommen.
      </p>
    </div>
    <div className="lg:w-2/12 lg:ml-20 flex items-center justify-center">
      <div className="mt-16 lg:mt-0 flex items-center justify-center h-full">
        <div className="w-24 lg:w-full mx-auto relative">
          <img src={pitchIcon} className="w-full" alt="Pitch 3min" />
          <div className="text-white bg-meteor-500 bg-gradient-l-mulberry-500 absolute uppercase font-display font-bold rounded-full w-16 h-16 lg:w-24 lg:h-24
          flex items-center justify-center" style={ { top: "-1.8em", right: "-3.7em" } }>
            Pitch
          </div>
        </div>
      </div>
    </div>
  </section>
)

Section1.propTypes = {
}

Section1.defaultProps = {
}

export default Section1
