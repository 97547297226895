import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import pitchIcon from '../icons/3_min_pitch_icon.svg'

const numberStyle = { color: '#EFC7C1', zIndex: '-1', fontSize: '13em' };

const Section2 = ({}) => (
  <div className="relative mb-16">
    <div className="rotated-bg absolute bg-vanilla-ice"></div>
    <section className="container py-12 md:py-20 lg:py-32 xl:py-40 xl2:py-40">
      <div className="text-sm text-gradient uppercase font-display font-bold">Pitch Night</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Typischer Ablauf</h2>
      <hr className="border-meteor-500 border-2 w-1/5 my-6" />

      <div className="lg:flex mt-10">
        <div className="lg:w-1/4 lg:px-6 relative lg:pt-24 lg:pb-5 flex items-center lg:block">
          <div className="lg:absolute top-0 left-0 leading-none font-bold font-display mr-4 lg:mr-0" style={numberStyle}>1</div>
          <div>
            <div className="font-bold font-display text-xl lg:text-2xl mb-4 text-gradient">Begrüßung</div>
            <div className="font-light leading-relaxed">Female Co-Founders & regionale Event-Partner stellen sich vor.</div>
          </div>
        </div>
        <div className="lg:w-1/4 lg:px-6 relative lg:pt-24 lg:pb-5 flex items-center lg:block">
          <div className="lg:absolute top-0 left-0 leading-none font-bold font-display mr-4 lg:mr-0" style={numberStyle}>2</div>
          <div>
            <div className="font-bold font-display text-xl lg:text-2xl mb-4 text-gradient">Input</div>
            <div className="font-light leading-relaxed">Expert*innen sprechen in Panelrunden, Vorträgen etc. zum Thema.</div>
          </div>
        </div>
        <div className="lg:w-1/4 lg:px-6 relative lg:pt-24 lg:pb-5 flex items-center lg:block">
          <div className="lg:absolute top-0 left-0 leading-none font-bold font-display mr-4 lg:mr-0" style={numberStyle}>3</div>
          <div>
            <div className="font-bold font-display text-xl lg:text-2xl mb-4 text-gradient">Pitch Session</div>
            <div className="font-light leading-relaxed">Gründer*innen pitchen Startup-Ideen in 3-minütigen Pitches.</div>
          </div>
        </div>
        <div className="lg:w-1/4 lg:px-6 relative lg:pt-24 lg:pb-5 flex items-center lg:block">
          <div className="lg:absolute top-0 left-0 leading-none font-bold font-display mr-4 lg:mr-0" style={numberStyle}>4</div>
          <div>
            <div className="font-bold font-display text-xl lg:text-2xl mb-4 text-gradient">Networking</div>
            <div className="font-light leading-relaxed">In lockeren Runden tauschen sich potentielle Co-Founder untereinander aus.</div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

Section2.propTypes = {
}

Section2.defaultProps = {
}

export default Section2
