import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import backgroundImg from '../photos/pitch_nights_stripe.jpg'

const Section4 = ({}) => (
  <div style={{ backgroundImage: `url(${backgroundImg})` }} className="bg-cover bg-center">
    <section className="container py-10 md:py-12 lg:py-24 xl2:py-32 text-center text-white">
      <div className="lg:w-6/12 mx-auto">
        <h2 className="font-display font-bold text-2xl lg:text-4xl leading-tight">
          Unser Event Newsletter
        </h2>

        <hr className="w-1/5 my-6 border-white mx-auto" />

        <p className="font-light leading-relaxed md:text-lg mb-8 lg:mb-12">
           Melde dich hier für den Female Co-Founders Event Newsletter an und bleibe immer up-to-date!
        </p>

        <a href="https://b44c5f10.sibforms.com/serve/MUIEAONfcfMauoUOsUGvRzeFM589wi1C08ti59gEp2gw421kLicI43z9O3TtyHlYeilM5Chg-NBscRwzh23YWUqruUTQ8siZMafhmJ7cUikMOLVHNVpoB129vDzs1O58p82mcc-PWmqjCauxUiGQJEGeUZKgkT3_aboIBzuNUab9bhWKAZMP0JcI4cQHPgFbYYwUD1290D42REb1"
        target="_blank" className="bg-white inline-block px-6 py-2 text-meteor-500 rounded hover:opacity-75">
          Anmelden
        </a>
      </div>
    </section>
  </div>
)

Section4.propTypes = {
}

Section4.defaultProps = {
}

export default Section4
