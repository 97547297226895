import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/pitch_nights_mobile_header.png'
import photo from '../photos/pitch_nights_header.png'

const Header = ({}) => (
  <div className="relative mb-16 bg-pampas" style={{ zIndex: 0 }}>
    <div className="rotated-bg absolute bg-pampas">
    </div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper pn hidden md:block">
      <div className="fc-header pn" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="container py-8 md:py-12 xl:py-24 xl2:py-42">
      <div className="md:w-8/12">
        <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-4">Events</div>
        <h1 className="text-4xl lg:text-6xl leading-none font-display font-bold text-gradient">By the community,<br />for the community</h1>
        <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 my-6" />
        <p className="font-light leading-relaxed">
          Uns ist daran gelegen, die vielen und guten Gründungsideen und Gründungspersönlichkeiten
          unserer Community sichtbar zu machen und diese aktiv zu vernetzen. Erfahre mehr über
          unsere Formate und melde dich gleich hier für unseren Event-Newsletter an, um auf dem Laufenden zu bleiben:
        </p>
          <a href="https://b44c5f10.sibforms.com/serve/MUIEAONfcfMauoUOsUGvRzeFM589wi1C08ti59gEp2gw421kLicI43z9O3TtyHlYeilM5Chg-NBscRwzh23YWUqruUTQ8siZMafhmJ7cUikMOLVHNVpoB129vDzs1O58p82mcc-PWmqjCauxUiGQJEGeUZKgkT3_aboIBzuNUab9bhWKAZMP0JcI4cQHPgFbYYwUD1290D42REb1"
            target="_blank" className="inline-block hover:opacity-75 px-6 py-2 rounded mt-4 text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Anmeldung Event-Newsletter
          </a>
      </div>
    </div>
  </div>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
